/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO, CompactSectionContentStyles, SectionWithContent, themeConfig } from '@core';
import { useHeight } from 'src/components/composite/useHeight';
import { getNumber } from '@modules';

export default () => {
  const height = useHeight();

  const iframeStyles = {
    mt: 'headerHeight',
    height: height - getNumber(themeConfig.sizes.headerHeight),
    border: 'debug',
    padding: '0 16px !important',
    bg: 'text',
    iframe: {
      border: 'none',
      width: '100%',
      height: height - getNumber(themeConfig.sizes.headerHeight),
      maxHeight: '710px',
    },
  };

  return (
    <Layout>
      <StaticSEO pageId='mission' />

      <SectionWithContent data-cy='adventure'>
        <CompactSectionContentStyles sx={iframeStyles}>
          <iframe title='Team Mission' src='https://i.simmer.io/@Leadable/~bd3e4155-b675-8caa-a627-6eaa54974f8c' />
        </CompactSectionContentStyles>
      </SectionWithContent>
    </Layout>
  );
};
